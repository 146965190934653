div.tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 105px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 20px;

  & > div.tile {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    background: var(--ion-color-light);
    border-radius: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.long-text {
      font-size: 13px;
    }

    &.very-long-text {
      font-size: 12px;
    }
  }
}
