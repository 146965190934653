/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import 'swiper/swiper-bundle.min.css';

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Custom styles */
@import 'colors';
@import "ion-input";
@import "ion-textarea";
@import "form";
@import "tile";
@import "profile-page";
@import "black-theme";
@import "button";
@import "gmap";
@import "paypal";
@import "modals";

/* index.html */
body {
  background: black;
  position: relative;
}

.bold {
  font-weight: bold;
}

.text-color-grey {
  color: var(--ion-color-medium);
}

div.error {
  padding: 5px 0 0 5px;
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0.35px;
  color: #FD5F5E;
}

ion-list {
  --ion-item-background: none;
}

ion-item {
  --background: none;
}

ion-modal.date-picker {
  --width: 290px;
  --height: 482px;
  --border-radius: 8px;

  & ion-datetime {
    height: 382px;
  }
}

ion-modal.input-sum {
  --width: 290px;
  --height: 280px;
  --border-radius: 8px;

  & form {
    height: 280px;
  }
}

ion-modal.modal-window {
  --background: rgba(0, 0, 0, 0.4) !important;
}

circle-progress svg {
  filter: drop-shadow(0px 18px 55px rgb(108 99 255 / 0.4));
}

.blur {
  -webkit-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

//my-tasks filter icons beginning

.filter-work {
  span {
    ion-icon {
      width: 15px;
      color: #FA0DFF !important;
    }
  }
}

.filter-done {
  span {
    ion-icon {
      width: 15px;
      color: #A55FFF !important;
    }
  }
}

.filter-published {
  span {
    ion-icon {
      width: 15px;
      color: #0057FF !important;
    }
  }
}

.filter-unpublished {
  span {
    ion-icon {
      width: 15px;
      color: #00BA7B !important;
    }
  }
}

//my-tasks filter icons ending

ion-button {
  text-transform: none;
}

.message-input {
  margin-right: 80px;
  --padding-end: 75px;
}

.header-chips {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > ion-chip {
    background: transparent;
    padding: 10px 23px;
    overflow: visible;
    font-size: 15px;
    height: auto;
    border-radius: 20px;
    color: var(--color-medium);
    margin: 0;

    &.selected {
      background: var(--color-medium-3);
      color: white;
    }
  }
}

avatar {
  height: 100%;
}

.no-padding {
  padding: 0 !important;
}

ion-input.sms-code {
  .native-input {
    max-height: initial;
  }
}

.alert-loader-abort {
  z-index: 51515151 !important;
}

.sr-only {
  display: block;
  height: 1px !important;
  width: 1px !important;
  margin-top: -1px !important;
  margin-right: -1px !important;
  overflow: hidden !important;
}