ion-input.custom-ion-input {
  margin-top: 10px;

  &.sc-ion-input-md-h, &.sc-ion-input-ios-h {
    --padding-start: 15px;
    --padding-top: 12px;
    --padding-end: 10px;
    --padding-bottom: 12px;
    background-color: var(--color-grey-2);
    border-radius: 10px;
    width: auto;
  }

  &.round {
    &.sc-ion-input-md-h, &.sc-ion-input-ios-h {
      border-radius: 30px;
      --padding-start: 15px;
      --padding-top: 13px;
      --padding-end: 10px;
      --padding-bottom: 13px;
    }
  }
}
