:root {
  --color-main-blue: #0057FF;
  --color-black: black;
  --color-green: #BDF050;
  --color-blue-light: #00A3FF;
  --color-light: #FAFAFA;
  --color-purple: #A55FFF;
  --color-red: #E80E0E;

  --color-dark-0: #1C1C1E;
  --color-dark-1: #262626;
  --color-dark-2: #363636;

  --color-grey-0: #555555;
  --color-grey-1: #1B1D25;
  --color-grey-2: #1D1D20;
  --color-medium: #989aa2;
  --color-medium-2: #272A35;
  --color-medium-3: #212328;

  --color-black-rgb: 0, 0, 0;
  --color-grey-0-rgb: 85, 85, 85;
  --color-dark-2-rgb: 54, 54, 54;
  --color-medium-rgb: 152, 154, 162;
}

ion-content {
  --background: var(--color-black);
}

ion-header {
  &.black {
    & > ion-toolbar {
      --background: black;
    }
  }

  &.transparent {
    box-shadow: none;

    & > ion-toolbar {
      --background: transparent;
      --ion-color-base: transparent !important;
    }
  }

  &.title-center {
    & > ion-toolbar {
      position: relative;
      & > ion-title {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.ios {
    --border-width: none;

    & > ion-toolbar {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

ion-footer {
  &.black {
    background: black;
  }
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0px;
}

ion-chip.people-chip {
  color: white;
  padding: 8px 15px;
  background: #3A3B41;
  font-size: 13px;
}
