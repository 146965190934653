ion-textarea.custom-ion-textarea {
  margin-top: 10px;

  &.sc-ion-textarea-md-h, &.sc-ion-textarea-ios-h {
    background-color: var(--color-grey-2);
    //padding: 3px !important;
    --padding-start: 15px;
    --padding-top: 13px;
    --padding-end: 10px;
    --padding-bottom: 13px;
    border-radius: 10px;
    width: auto;
  }

  &.round {
    &.sc-ion-textarea-md-h, &.sc-ion-textarea-ios-h {
      border-radius: 30px;
      --padding-start: 15px;
      --padding-top: 13px;
      --padding-end: 10px;
      --padding-bottom: 13px;
    }
  }
}
