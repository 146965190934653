/* hide google maps conditions */
.gmnoprint {
  display: none;
}

/* hide google maps logo */
//div[style*="position: absolute; left: 0px; bottom: 0px;"] {
//  display: none;
//}

div[style*="position: absolute; left: 0px; bottom: 0px;"] a[href^="https://maps.google.com/maps"] div {
  display: none;
}

//div[style*="position: absolute; left: 0px; bottom: 0px;"] a[href^="https://maps.google.com/maps"] img {
//    display: none;
//}

/* google maps error message */
.gm-err-content {
  background: #000000;
  padding-bottom: 25vh;

  .gm-err-title, .gm-err-message {
    color: #a1a1a1;
  }
}
