form.custom-form {

  & > h2.title {
    font-size: 22px;
    margin-bottom: 20px;
  }

  & > div.field {
    & > div {
      width: 100%;
    }

    ion-textarea.custom-ion-textarea, ion-input.custom-ion-input {
      margin: 10px 0 0 0;

      &.without-margin {
        margin: 0;
      }
    }
  }

  &.question {
    & > div.category-name-in-question {
      color: var(--color-medium);
      font-size: 13px;
      margin-bottom: 0px;
      margin-top: 30px;
    }

    & > h2.title {
      font-size: 22px;
      margin-top: 0;
    }
  }
}
