.profile-page-header {
  padding-top: 8px;

  ion-toolbar {
    ion-buttons {
      ion-button {
        height: 100%;

        ion-icon {
          border-radius: 50%;
          box-sizing: border-box;

          &.add {
            padding: 6px;
            height: 25px;
            width: 25px;
            background: white;
            color: black;
          }

          &.menu {
            padding: 6px;
            height: 25px;
            width: 25px;
            color: white;
          }
        }
      }
    }
  }
}

div.profile-page {
  width: 100%;
  position: relative;
  overflow: auto;

  div.header {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: black;

    &.expanded-header {
      height: auto;

      & > div.back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        padding: 10px 10px 20px 10px;
        display: grid;
        overflow: hidden;
        vertical-align: center;

        & > img {
          width: 100%;
        }
      }

      & > div.front {
        height: auto;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        & > div.info {
          z-index: 5;
          flex-grow: 1;
          flex-shrink: 1;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          & > app-avatar {
            margin: 55px 0 0;
          }

          & > div.name {
            margin-top: 30px;
            font-weight: 500;
            font-size: 24px;
            color: white;
          }

          & > div.description {
            color: white;
            font-weight: 300;
            font-size: 12px;
            margin-top: 10px;
            margin-right: 30px;
            margin-left: 30px;
            text-align: center;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
          }

          & > div.rating-info {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            color: white;

            & > div.stars {
              .value {
                margin-right: 3px;
              }

              .icon {
                height: 11px;
              }
            }

            & > div.visitors {
              .value {
                margin-left: 3px;
              }

              .icon {
                position: relative;
                top: 1px;
                height: 11px;
              }
            }

            & > div.rate {
              .value {
                margin-left: 3px;
              }

              .icon {
                position: relative;
                top: 1px;
                height: 11px;
              }
            }
          }

          & > div.suggest-btn {
          }
        }
      }
    }

    &.compressed-header {
      height: 50px;
      width: 100%;
      z-index: 3;
      position: sticky;
      margin-top: -50px;
      top: 0;
      opacity: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 15px 0 15px 15px;
      background-color: black;

      & > div.back-btn {
        flex-grow: 0;
        flex-shrink: 0;
      }

      & > div.name {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 18px;
        color: white;
      }

      & > div.buttons {
        flex-grow: 0;
        flex-shrink: 0;
        width: min-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 8px;

        & > ion-icon {
          border-radius: 50%;
          box-sizing: border-box;

          &.add {
            padding: 6px;
            height: 25px;
            width: 25px;
            background: white;
            color: black;
            margin-right: 8px;
          }

          &.menu {
            padding: 6px;
            height: 25px;
            width: 25px;
            color: white;
          }
        }
      }
    }
  }

  div.category {
    background: black;
    position: sticky;
    top: 49px;
    width: 100%;
    z-index: 1;

    ion-segment {
      ion-segment-button {
        min-height: 30px;
        height: 35px;
        text-transform: none;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;


        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
  }

  /*div.content {
    height: auto;
    background-color: black;
    padding-bottom: 62px;

    & > div.load-file-button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      ion-button {
        margin: 65px auto 0 auto;
      }

      span.empty {
        color: var(--color-grey-0);
        margin-top: 50px;
      }
    }

    //& > div.files {
    //  display: grid;
    //  grid-template-columns: 1fr 1fr 1fr;
    //  grid-auto-flow: row dense;
    //  gap: 5px;
    //  padding-bottom: 70px;
    //
    //  & > div {
    //    position: relative;
    //
    //    border-radius: 10px;
    //    overflow: hidden;
    //
    //    cursor: pointer;
    //    user-select: none;
    //
    //    &:before {
    //      content: '';
    //      display: block;
    //      padding-top: 133%;
    //    }
    //
    //    &:nth-child(12n + 1) {
    //      grid-column: span 2;
    //      grid-row: span 2;
    //    }
    //
    //    &:nth-child(12n + 7) {
    //      grid-column: 2 / span 2;
    //      grid-row: span 2;
    //    }
    //
    //    img {
    //      position: absolute;
    //      top: 0;
    //      right: 0;
    //      bottom: 0;
    //      left: 0;
    //      width: 100%;
    //      height: 100%;
    //      object-fit: cover;
    //      font-size: 15px;
    //    }
    //
    //    & > ion-icon {
    //      position: absolute;
    //      top: 10px;
    //      right: 10px;
    //      background-color: var(--color-dark-0);
    //      border-radius: 50%;
    //    }
    //  }
    //}
    //
    //& > div.files-docs {
    //  display: grid;
    //  grid-template-columns: 1fr 1fr;
    //  padding-bottom: 60px;
    //
    //  & > div.file {
    //    display: flex;
    //    flex-direction: row;
    //    align-items: center;
    //    justify-content: flex-start;
    //    padding: 10px;
    //    padding-right: 25px;
    //    border-radius: 15px;
    //    background: rgba(black, 0.3);
    //    margin-bottom: 4px;
    //    position: relative;
    //
    //    & > div.icon {
    //      flex-shrink: 0;
    //      flex-grow: 0;
    //      padding-right: 10px;
    //
    //      & > img {
    //        height: 40px;
    //        user-select: none;
    //      }
    //    }
    //
    //    & > div.info {
    //      flex-grow: 1;
    //      flex-shrink: 1;
    //
    //      & > div.name {
    //        font-weight: 700;
    //        font-size: 14px;
    //        display: -webkit-box;
    //        -webkit-line-clamp: 2;
    //        -webkit-box-orient: vertical;
    //        overflow: hidden;
    //        white-space: normal;
    //        text-overflow: ellipsis;
    //        word-break: break-word;
    //      }
    //
    //      & > div.size {
    //        font-size: 12px;
    //        margin-top: 5px;
    //        color: var(--color-medium);
    //      }
    //    }
    //
    //    & > div.close {
    //      & > ion-icon {
    //        position: absolute;
    //        top: 10px;
    //        right: 0;
    //        background-color: var(--color-dark-0);
    //        border-radius: 50%;
    //      }
    //    }
    //  }
    //}
  }*/
}

div.files-slot {
  div.content {
    height: auto;
    background-color: black;
    padding-bottom: 62px;

    & > div.load-file-button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      ion-button {
        margin: 65px auto 0 auto;
      }

      span.empty {
        color: var(--color-grey-0);
        margin-top: 50px;
        text-transform: capitalize;
      }
    }
  }
}

ion-content.ios {
  div.profile-page {
    div.content {
      & > div.files {
        padding-bottom: 100px;
      }
    }
  }
}

div.profile-segments {
  ion-segment {
    ion-segment-button {
      min-height: 35px;
    }

    ion-label {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

div.rating-slot {
  div.content {
    & > div.empty-slot {
      width: 100%;
      color: var(--color-grey-0);
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
    }

    & > div.header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      & > div.title {
        font-size: 22px;
        font-weight: 600;
      }

      & > div.star {
        font-size: 18px;

        ion-icon {
          display: inline-block;
          margin-right: 5px;
          margin-bottom: 1px;
          width: 12px;
          height: 12px;
        }
      }
    }

    & > div.rating {
      padding: 15px 0;
      border-bottom: 1px solid rgba(131, 131, 131, 0.5);

      & > div.my-rating {
        font-size: 16px;
        font-weight: 500;
      }

      & > div.not-my-rating {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        & > div.avatar {
          flex-shrink: 0;
          flex-grow: 0;
          margin-right: 10px;
        }

        & > div.name {
          flex-shrink: 1;
          flex-grow: 1;
          text-overflow: ellipsis;
        }
      }

      & > div.rate-info {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        & > div.stars {
          margin-right: 10px;
        }

        & > div.date {
          font-size: 11px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          text-align: left;
          color: #A4A39B;
          vertical-align: middle;
          padding-top: 3px;
        }
      }

      & > div.category {
        margin-top: 5px;
        font-size: 11px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        color: #A4A39B;
      }

      & > div.description {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-align: left;
        color: white;
        margin-top: 10px;
      }

      &:last-child {
        margin-bottom: 70px;
      }
    }
  }
}

